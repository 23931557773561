import React, { type FC } from 'react';
import classNames from 'classnames';

import styles from './manager.module.scss';
import successPng from './images/success.png';

import { type Props } from './Manager.types';
import { useTranslations } from '../../../../i18n/utils';
import { TelegramSvg } from './images/telegram';

export const Manager: FC<Props> = ({ manager, className, lang }) => {
  const t = useTranslations(lang);
  const managerName = manager.replace('@', '');

  return (
    <div className={classNames(styles.manager, className)}>
      <img src={successPng.src} alt='Success' />
      <h4 className={styles.title}>{t('register.manager.title')}</h4>
      <p className={styles.text}>{t('register.manager.text')}</p>
      <a href={`https://t.me/${managerName}`} className={styles.link}>
        <TelegramSvg /> {`${managerName}`}
      </a>
    </div>
  );
};
