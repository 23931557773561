import Cookies from 'js-cookie';
import { googleSheetStore } from '../googleSheet';
import { appConfig } from '../../config';
import * as Sentry from '@sentry/browser';

export const addToGoogleSheet = async (formData: FormData) => {
  try {
    formData.append('Referer', document.referrer || window.location.href);

    const response = await fetch(appConfig.public.googleMacros, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      googleSheetStore.set({ error: 'Error', isSuccess: false, manager: '' });
      return;
    }

    const data = await response.json();
    googleSheetStore.set({ error: '', isSuccess: true, manager: data.manager });
    Cookies.set('manager', data.manager);

    // Отправка события Facebook Pixel
    if (typeof fbq === 'function') {
      fbq('track', 'CompleteRegistration');
    }

    return data;
  } catch (error) {
    const err = new Error(`GoogleSheets error: ${error}`);
    Sentry.captureException(err);
    console.error('An error occurred:', error);
    throw error;
  }
};
