import { atom } from 'nanostores';

type MeState = {
  error: string;
  isSuccess: boolean;
  isLoading: boolean;
};

export const meStore = atom<MeState>({
  error: '',
  isSuccess: false,
  isLoading: false,
});
