import { atom } from 'nanostores';

type GoogleSheetState = {
  error: string;
  isSuccess: boolean;
  manager: string;
};

export const googleSheetStore = atom<GoogleSheetState>({
  error: '',
  isSuccess: false,
  manager: '',
});
