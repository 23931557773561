import { z } from 'zod';

const required = {
  required_error: 'register.form.required_error',
};

export const passwordRegex =
  /^(?=.*[A-Za-z\d])[A-Za-z\d*.!@$%^&():;,.?~_+-=]{6,}$/;

export const telegramRegex = /^@[A-Za-z0-9_]{5,32}$/;

export const schema = z.object({
  email: z.string(required).email('register.form.invalid_email'),
  password: z
    .string()
    .min(6, 'register.form.password_min')
    .max(30, 'register.form.password_max')
    .regex(passwordRegex, 'register.form.password_invalid'),
  telegram: z
    .string(required)
    .trim()
    .transform((val) => val.replace(/[<>]/g, ''))
    .pipe(z.string().regex(telegramRegex, 'register.form.telegram_invalid')),
});
