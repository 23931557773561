import { atom } from 'nanostores';

type RegisterState = {
  error: string;
  isSuccess: boolean;
  isLoading: boolean;
};

export const registerStore = atom<RegisterState>({
  error: '',
  isSuccess: false,
  isLoading: false,
});
