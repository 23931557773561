export const TelegramSvg = () => {
  return (
    <svg
      width='16'
      height='14'
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.9198 0.09257L0.746936 5.79549C-0.220382 6.20038 -0.214645 6.76324 0.57062 7.01423L4.10537 8.16521L5.45784 12.4911C5.62226 12.9646 5.54121 13.1524 6.01779 13.1524C6.38558 13.1524 6.54874 12.9775 6.75358 12.7687C6.88385 12.6357 7.65727 11.8512 8.52085 10.9753L12.1977 13.8094C12.8742 14.1988 13.3628 13.997 13.5312 13.1538L15.9448 1.28841C16.1919 0.254879 15.5671 -0.213927 14.9198 0.09257ZM4.66011 7.90077L12.6274 2.65672C13.0252 2.40508 13.3899 2.54037 13.0904 2.81766L6.26832 9.23916L6.00269 12.1949L4.66011 7.90077Z'
        fill='black'
      />
    </svg>
  );
};
