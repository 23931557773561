import { appConfig } from '../../config';
import { api } from '../../utils';

export const fetchMe = async (onSuccess: () => void) => {
  try {
    const { data, status } = await api.get('/v1/me', {
      baseURL: appConfig.public.servicePartnerUrl,
    });

    if (status === 200) {
      onSuccess();
      return data;
    }

    return 'Error';
  } catch (error) {
    console.error('Failed to fetch:', error);
  }
};
