import { api } from '../../utils';
import Cookies from 'js-cookie';
import { registerStore } from '../register';
import * as Sentry from '@sentry/browser';

type FetchRegisterPayload = {
  country_id?: number;
  currency_id?: number;
  language_id?: number;
  email: string;
  password: string;
};

export const fetchRegister = async (
  body: FetchRegisterPayload,
  onSuccess: () => void,
) => {
  const firstVisitParams = Cookies.get('first_visit_params');

  const requestData = {
    ...body,
    auth: 1,
    partner_params: {
      ...(firstVisitParams ? JSON.parse(firstVisitParams) : null),
    },
    partner: 1,
  };
  registerStore.set({ error: '', isSuccess: false, isLoading: true });
  try {
    const { data, status } = await api.post('/userdatas', requestData);

    if (status === 201) {
      registerStore.set({ error: '', isLoading: false, isSuccess: true });
      Cookies.set('access_token', data.access_token);
      Cookies.set('refresh_token', data.refresh_token);
      Cookies.set('expires_in', data.expires_in);

      onSuccess();
      return data;
    }

    registerStore.set({
      error: data?.message,
      isLoading: false,
      isSuccess: false,
    });
    return 'Error';
  } catch (error) {
    const err = new Error(`Register error: ${error}`);
    Sentry.captureException(err);
    console.error('Failed to fetch:', error);
  }
};
